import "../../../../scss/Fields.scss";
import React, {useEffect, useRef, useState} from "react";
import CustomSelectBox from "./inc/CustomSelectBox.jsx";
import {fetchCorrectPriceTable} from "../../../functions/price-functions.jsx";

export default function MeasurementField(props) {
    const {item, option, index, fieldsData, setFieldsData, toLower, selectedConfigurator, missingPriceData, priceData, componentData, setComponentData, stepIndex, setCaptureMeasurementError } = props;

    const [toggles, setToggles] = useState([]); // Array of toggle states
    const [error, setError] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const inputRef = useRef(null); // Add a ref to the input element

    let initialCountRef = useRef(0);

    useEffect(() => {
        // Detect if the device supports touch events
        const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        setIsTouchDevice(isTouch);
    }, []);

    useEffect(() => {
        // Add default value to breedte & hoogte
        if (!!option.label && initialCountRef.current <= 1) {
            if (!fieldsData['afmeting-breedte'] && option.label === "Breedte") {
                setFieldsData({
                    ...fieldsData,
                    [toLower(item.label, option.label)]: option.min,
                });
            }

            if (!fieldsData['afmeting-hoogte'] && option.label === "Hoogte" && selectedConfigurator !== "zonnescherm") {
                setFieldsData({
                    ...fieldsData,
                    [toLower(item.label, option.label)]: option.min,
                });
            }
            initialCountRef.current++;
        }

        if(initialCountRef.current <= 2) {
            const URLParams = getQueryParamValue(toLower(item.label, option.label));

            if (URLParams && URLParams[toLower(item.label, option.label)]) {
                const paramValue = parseInt(URLParams[toLower(item.label, option.label)], 10);
                if (paramValue <= option.min && paramValue >= option.max) {
                    setFieldsData({
                        ...fieldsData,
                        [toLower(item.label, option.label)]: option.min,
                    });
                }
            }
        }
    }, [fieldsData, initialCountRef]);

    useEffect(() => {
        const correctPriceTable = fetchCorrectPriceTable(selectedConfigurator, fieldsData, priceData, fieldsData['afmeting-breedte'], fieldsData['afmeting-hoogte']);
        if (correctPriceTable) {
            const {minWidth, maxWidth} = getMinMaxWidthWithPrice(correctPriceTable.table);
            if (typeof minWidth === 'number' && typeof maxWidth === 'number') {
                let updatedComponentData = [...componentData];

                if (updatedComponentData && updatedComponentData[stepIndex]) {
                    let measurementStep = updatedComponentData[stepIndex];

                    if (measurementStep && measurementStep['form_fields'] && measurementStep['form_fields'][0] && measurementStep['form_fields'][0]['form_field']) {
                        const options = measurementStep['form_fields'][0]['form_field']['options'];

                        if (Array.isArray(options)) {
                            const widthIndex = options.findIndex(option => option.label === "Breedte");

                            if (widthIndex !== -1 && options[widthIndex]) {
                                const updatedOptions = [...options];
                                if(selectedConfigurator !== "rolluik") {
                                    updatedOptions[widthIndex] = {...updatedOptions[widthIndex], min: minWidth, max: maxWidth};
                                } else{
                                    // @TODO: remove hardcoded width values from initial data load, and gather max width here.(for rolluiken.)
                                    updatedOptions[widthIndex] = {...updatedOptions[widthIndex], min: minWidth};
                                }

                                const updatedMeasurementStep = {
                                    ...measurementStep,
                                    form_fields: [
                                        {
                                            ...measurementStep['form_fields'][0],
                                            form_field: {
                                                ...measurementStep['form_fields'][0]['form_field'],
                                                options: updatedOptions
                                            }
                                        }
                                    ]
                                };

                                const updatedComponentDataWithMeasurementStep = [...updatedComponentData];
                                updatedComponentDataWithMeasurementStep[stepIndex] = updatedMeasurementStep;

                                if (JSON.stringify(updatedComponentDataWithMeasurementStep) !== JSON.stringify(componentData)) {
                                    console.info('%cUpdated component data with new min and max width values', 'color: blue; font-weight: bold; background: #f0f0f0; padding: 4px; border-radius: 4px;');
                                    setComponentData(updatedComponentDataWithMeasurementStep);

                                    if (inputRef.current && !!inputRef.current.value && inputRef.current.getAttribute('name') === 'afmeting-breedte') {
                                        setTimeout(function() { inputRef.current.focus(); }, 50);
                                        setTimeout(function() { inputRef.current.blur(); }, 150);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [option, priceData]);

    useEffect(() => {
        setCaptureMeasurementError(error);
    }, [error]);

    const getQueryParamValue = () => {
        // Get the search parameters from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        const configurationParam = searchParams.get('configuration');

        // Get the value associated with the specified key
        return JSON.parse(configurationParam);
    }

    const handleInputInteraction = (e, type, shouldDelete = false) => {
        if(shouldDelete) {
            const shallowCopyFieldsData = {...fieldsData};
            delete shallowCopyFieldsData[toLower(item.label, option.label)];
            setFieldsData(shallowCopyFieldsData);
            return true;
        }

        if (!!e.target && !!type && type === "number" && !!e.target.value && parseInt(e.target.value) > 0) {
            setFieldsData({
                ...fieldsData,
                [e.target.name]: e.target.value,
            });
        } else if (e.target.value === "" || parseInt(e.target.value) <= 0) {
            setFieldsData({
                ...fieldsData,
                [e.target.name]: "0"
            });
        }
    };

    const createRecommendLabel = (option) => {
        if(!!option.min && !!option.max) {
            return `(${option.min} - ${option.max} mm)`;
        }
    }

    const setToggle = (customKey, value) => {
        setToggles((prevToggles) => ({
            ...prevToggles,
            [customKey]: value,
        }));
    };

    const getMinMaxWidthWithPrice = (table) => {
        // Ensure we're only dealing with numeric keys and their values aren't empty
        const numericWidths = Object.keys(table).filter(key => !isNaN(key)).map(Number);
        const validWidths = numericWidths.filter(width => table[width] !== "" && table[width] != null);

        // Find min and max width values
        const minWidth = Math.min(...validWidths);
        const maxWidth = Math.max(...validWidths);

        // Use the min and max to get corresponding prices
        const minPrice = table[minWidth];
        const maxPrice = table[maxWidth];

        return { minWidth, maxWidth, minPrice, maxPrice };
    }

    const handleWidthChange = (e, isMobile = false) => {
        const newValue = parseInt(e.target.value, 10);
        if (newValue >= option.min && newValue <= option.max) {
            setError(false);
            // Only update the value if it's within the min and max range
            handleInputInteraction(e, "number");
        } else {
            setError(true);
            handleInputInteraction(e, "number", true);

          if(isMobile) {
              // Trigger onBlur programmatically
              if (inputRef.current && inputRef.current.getAttribute('name') === 'afmeting-breedte') {
                  setTimeout(function() { inputRef.current.focus(); }, 50);
                  inputRef.current.focus();
              }
          }
        }
    }

    return (
        <div key={item.label.toLowerCase() + index}
             className={`input-${item.form_fields[0]["form_field"]["form_field_type"]} ${option.image ? 'with-image' : ""}${option['apply_conditional_logic'] ? option['apply_conditional_logic'] : ''} ${error ? 'error' : ''}`}
             style={fieldsData[item.label.toLowerCase()] === option.label ? checkedInputStyle : null}
        >
            {selectedConfigurator === "zonnescherm" && option.label === "Hoogte" ? (
                <>
                    <span className="custom-label">Uitval</span>
                    <CustomSelectBox
                        customKey={"O" + index}
                        toggle={toggles}
                        setToggle={setToggle}
                        data={option}
                        dataKey={toLower(item.label, option.label)}
                        typeKey={"number"}
                        item={item}
                        fieldsData={fieldsData}
                        handleInputInteraction={handleInputInteraction}
                        boxForStep={"dimensionDrop"}
                        index={index}
                        setFieldsData={setFieldsData}
                        missingPriceData={missingPriceData}
                        priceData={priceData}
                    />
                </>
            ) : (
                <>
                    <label htmlFor={item.label}>
                        {option.label + " "}
                    </label>
                    <div key={fieldsData[toLower(item.label, option.label)]} className={'field-group'}>
                        <input
                            ref={inputRef}
                            name={toLower(item.label, option.label)}
                            type="number"
                            min={option.min}
                            max={option.max}
                            checked={fieldsData[item.label.toLowerCase()] === option.label}
                            onBlur={(e) => {
                                !isTouchDevice ? handleWidthChange(e) : null
                            }}
                            onChange={(e) => {
                                isTouchDevice ? handleWidthChange(e, true) : null
                            }}
                            defaultValue={fieldsData[toLower(item.label, option.label)]}
                            onKeyDown={(evt) => {
                                const disallowedKeys = ['e', '.', ','];

                                if (disallowedKeys.includes(evt.key)) {
                                    evt.preventDefault();
                                }
                            }}
                        />
                        <span className="prefix">MM</span>
                    </div>
                    <span className="recommend-label">{createRecommendLabel(option)}</span>
                </>
            )}
        </div>
    );
}